.hamburger {
  border-radius: 15px;
  padding: 3px;
  margin-left: 80px;
}
.hamburger:hover {
  cursor: pointer;
  background-color: lightgray;
}

.screen-edge-right {
  margin-right: 80px;
}

.screen-edge-left {
  margin-left: 80px;
}

.screen-edge {
  margin: 0px 80px;
}

.social-footer {
  margin: 10px 0px 10px 80px;
}

.drawer-left {
  margin-left: 10px;
}
